export type Networks = 18 | 108

type IAddr = {
  [key in Networks]: string
}

export const ramToken = {
  address: '',
  symbol: 'RAM',
  decimals: 18,
  image: '', // 'https://foo.io/token-image.svg'
}

export const startingBlock: { [key in Networks]: number } = {
  18: 40000000,
  108: 77693953,
}

// testnet network ID: 18, mainnet network ID: 108
export const contractAddresses: { [name: string]: IAddr } = {
  sicbo: {
    18: '0x72F5a4B049Cf5f39F4F0276Ba155772AE02cdC2a',
    108: '0xDc9eFBE9591A3F4b0237c7F2D9c7b0A0198434ca',
  },
}

export const ttSwapHost: any = {
  18: 'https://testnet-ttswap.platform.dev.tt-eng.com/',
  108: 'https://ttswap.space',
}

export const acornHost: any = {
  18: 'https://acornstable.dapp.dev.tt-eng.com',
  108: 'https://acornstable.fi',
}

export const farmingStartingBlock: any = {
  18: 40000000,
  108: 78464020,
}

export const displayItems = 20
