import { Switch, Route } from "react-router-dom";
import Home from "containers/Home";
import Game from "containers/Game";
import React from "react";

const routes = [
  // { key: "Pair", path: "/pair/(user|admin)/:pairAddress", component: Pair },
  { exact: true, key: "app", path: "/home", component: Home },
  { exact: true, key: "game", path: "/", component: Game },
];

export default function RenderRoutes() {
  return (
    <Switch>
      {routes.map((route) => (
        <Route
          path={route.path}
          exact={route.exact}
          key={route.key}
          render={(props) => <route.component {...props} />}
        />
      ))}
    </Switch>
  );
}
