import Bo_1_White from "assets/img/Sic-Bo_1_White.png";
import Bo_1_Yellow from "assets/img/Sic-Bo_1_Yellow.png";
import Bo_2_White from "assets/img/Sic-Bo_2_White.png";
import Bo_2_Yellow from "assets/img/Sic-Bo_2_Yellow.png";
import Bo_3_White from "assets/img/Sic-Bo_3_White.png";
import Bo_3_Yellow from "assets/img/Sic-Bo_3_Yellow.png";
import Bo_4_White from "assets/img/Sic-Bo_4_White.png";
import Bo_4_Yellow from "assets/img/Sic-Bo_4_Yellow.png";
import Bo_5_White from "assets/img/Sic-Bo_5_White.png";
import Bo_5_Yellow from "assets/img/Sic-Bo_5_Yellow.png";
import Bo_6_White from "assets/img/Sic-Bo_6_White.png";
import Bo_6_Yellow from "assets/img/Sic-Bo_6_Yellow.png";

export default function Dices({
  activeChip,
  betList,
  setbetList,
  clearBets,
  onRoll,
  isRollDisabled,
  setisHistoryShow,
}: {
  activeChip: number;
  betList: number[];
  setisHistoryShow: React.Dispatch<React.SetStateAction<boolean>>;
  setbetList: React.Dispatch<React.SetStateAction<number[]>>;
  clearBets: () => void;
  onRoll: () => void;
  isRollDisabled: boolean;
}) {
  const updateBet = (dice: number) => {
    if (activeChip !== 0) {
      const newAmt = betList[dice - 1] + activeChip;
      setbetList((prev) => {
        prev[dice - 1] = newAmt;
        console.log("prev", prev);
        return prev;
      });
    }
  };

  return (
    <div className="content-block">
      <h3>Place your bets on dices</h3>
      <div className="dices-group">
        <div className="single-dice" onClick={() => updateBet(1)} id="dice-1">
          <div className="dice-img">
            <img alt="dice" className="dice-white" src={Bo_1_White} />
            <img alt="dice" className="dice-yello" src={Bo_1_Yellow} />
          </div>
          <div className="price">0TT</div>
        </div>
        <div className="single-dice" onClick={() => updateBet(2)} id="dice-2">
          <div className="dice-img">
            <img alt="dice" className="dice-white" src={Bo_2_White} />
            <img alt="dice" className="dice-yello" src={Bo_2_Yellow} />
          </div>
          <div className="price">0TT</div>
        </div>
        <div className="single-dice" onClick={() => updateBet(3)} id="dice-3">
          <div className="dice-img">
            <img alt="dice" className="dice-white" src={Bo_3_White} />
            <img alt="dice" className="dice-yello" src={Bo_3_Yellow} />
          </div>
          <div className="price">0TT</div>
        </div>
      </div>
      <div className="dices-group">
        <div className="single-dice" onClick={() => updateBet(4)} id="dice-4">
          <div className="dice-img">
            <img alt="dice" className="dice-white" src={Bo_4_White} />
            <img alt="dice" className="dice-yello" src={Bo_4_Yellow} />
          </div>
          <div className="price">0TT</div>
        </div>
        <div className="single-dice" onClick={() => updateBet(5)} id="dice-5">
          <div className="dice-img">
            <img alt="dice" className="dice-white" src={Bo_5_White} />
            <img alt="dice" className="dice-yello" src={Bo_5_Yellow} />
          </div>
          <div className="price">0TT</div>
        </div>
        <div className="single-dice" onClick={() => updateBet(6)} id="dice-6">
          <div className="dice-img">
            <img alt="dice" className="dice-white" src={Bo_6_White} />
            <img alt="dice" className="dice-yello" src={Bo_6_Yellow} />
          </div>
          <div className="price">0TT</div>
        </div>
      </div>

      <div className="bets-functions">
        <div>
          <button
            id="roll-dice"
            type="button"
            className="roll-the-dice"
            onClick={onRoll}
            disabled={isRollDisabled}
          >
            <span>Roll the Dice!</span>
          </button>
        </div>
        <div>
          <button
            id="clear-bet"
            type="button"
            className="opaque-btn"
            onClick={clearBets}
          >
            CLEAR BET
          </button>
          <button
            type="button"
            className="opaque-btn"
            onClick={() => setisHistoryShow((prev) => !prev)}
          >
            Recent History
          </button>

        </div>
      </div>
    </div>
  );
}
