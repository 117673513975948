import { useCallback, useEffect, useState } from "react";
import { useWalletWrapper } from "context/Wallet";
import { bigZero, getProvider } from "utils/ethers";

export function useGetUserTTBalance(timeOut?: number) {
  const { account, ethereum } = useWalletWrapper();
  const [balance, setbalance] = useState(bigZero);

  const getUserTTBalance = useCallback(async () => {
    if (account) {
      const provider = getProvider(ethereum);
      const userBalance = await provider.getBalance(account);
      setbalance(userBalance);
    }
  }, [account, setbalance, ethereum]);

  useEffect(() => {
    const timeoutID = setInterval(() => {
      getUserTTBalance();
    }, timeOut || 5000);

    getUserTTBalance();

    return () => {
      clearInterval(timeoutID);
    };
  }, [getUserTTBalance, timeOut]);

  return balance;
}
