import Header from "components/Header";
import React from "react";

export default function Home() {
  return (
    <div className="content index">
      <div className="container">
        <Header />
        <div className="hr" />
        <div className="content-block">
          <div className="single-item">
            <div className="title">On-chain game</div>
            <div className="description">
              This is a game built on{" "}
              <a className="anchor" href="https://thundercore.com">
                ThunderCore
              </a>{" "}
              blockchain.
            </div>
          </div>
          <div className="single-item">
            <div className="title">Proven Fair</div>
            <div className="description">
              We use the Random Number Generator provided by the chain to ensure
              fairness
            </div>
          </div>
          <div className="single-item">
            <div className="title">Game Rules</div>
            <div className="description">
              A single dice bet is placed on a specific number from 1-6 to
              appear on one, two, or all three dice. Betting on a number to
              appear once pays 1 to 1, twice 2 to 1, and three times 10 to 1.
            </div>
          </div>
          <div className="single-item">
            <div className="title">Connect a wallet</div>
            <div className="description">
              <div>
                <a className="anchor" href="https://trustwallet.com/deeplink/">
                  TrustWallet
                </a>
              </div>
              <div>
                <a className="anchor" href="https://hub.thundercore.com">
                  Thundercore Hub
                </a>
              </div>
              <div>
                <a className="anchor" href="https://metamask.io/download">
                  Metamask
                </a>
              </div>
              <div>
                <a className="anchor" href="https://token.im/download">
                  imToken
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
