import { displayItems, startingBlock } from "constants/index";
import { useWalletWrapper } from "context/Wallet";
import { BigNumber } from "ethers";
import { useSicboContract } from "hooks/useContract";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { bigZero, getProvider } from "utils/ethers";
import { networkID } from "utils/helpers";

export interface IBetResult {
  won: BigNumber;
  bets: BigNumber[];
  rolled: BigNumber[];
  user?: string;
}
interface IGame {
  getBetResults: () => Promise<void>;
  allBetResults: IBetResult[];
  minMax: BigNumber[];
  block: number;
}

export const initBetResult = { won: bigZero, bets: [], rolled: [], user: "" };

export const GameContext = createContext<IGame>({
  getBetResults: () => Promise.resolve(),
  allBetResults: [],
  minMax: [bigZero, bigZero],
  block: 0,
});

export const searchPastBlocks = 3600;

export const GameProvider: React.FC = ({ children }) => {
  const sicbo = useSicboContract();
  const { ethereum } = useWalletWrapper();
  const [allBetResults, setallBetResults] = useState<IBetResult[]>([]);
  const [minMax, setminMax] = useState<BigNumber[]>([bigZero, bigZero]);
  const [block, setblock] = useState(startingBlock[networkID]);

  const getBetResults = useCallback(async () => {
    if (sicbo) {
      const provider = getProvider(ethereum);
      const blockNumber = (await provider.getBlockNumber()) as number;
      const betResultEvents = await sicbo.queryFilter(
        // @ts-ignore
        "BetResult",
        blockNumber - searchPastBlocks
      );
      
      setblock(blockNumber);
      const betResults = betResultEvents
        .sort((a, b) => b.blockNumber - a.blockNumber)
        .slice(0, displayItems)
        .map((res) => {
          // @ts-ignore
          const { bets, user, won, rolled } = res.args;
          return { bets, user, won, rolled };
        });
      setallBetResults(betResults);
    }
  }, [sicbo, ethereum]);

  const getContractData = useCallback(async () => {
    if (sicbo) {
      const [minBetValue, maxBetValue] = await Promise.all([
        sicbo.minBetValue(),
        sicbo.maxBetValue(),
      ]);
      setminMax([minBetValue, maxBetValue]);
    }
  }, [sicbo]);

  useEffect(() => {
    getBetResults();
    getContractData();
  }, [getBetResults, getContractData]);

  return (
    <GameContext.Provider
      value={{ block, getBetResults, allBetResults, minMax }}
    >
      {children}
    </GameContext.Provider>
  );
};

export function useGameContext() {
  const game = useContext<IGame>(GameContext);
  return game;
}
