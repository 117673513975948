import { useCallback, useEffect, useState } from "react";
import Dice1 from "assets/img/Sic-Bo_Line_1_White.png";
import Dice2 from "assets/img/Sic-Bo_Line_2_White.png";
import Dice3 from "assets/img/Sic-Bo_Line_3_White.png";
import Dice4 from "assets/img/Sic-Bo_Line_4_White.png";
import Dice5 from "assets/img/Sic-Bo_Line_5_White.png";
import Dice6 from "assets/img/Sic-Bo_Line_6_White.png";
import Icon_Result from "assets/img/Icon_Result.png";
import Photo_Transitions from "assets/img/Photo_Transitions.png";
import {
  init,
  onOKClick,
  renderBetResults,
  updateSelectChip,
} from "scripts/script";
import { useSicboContract } from "hooks/useContract";
import { useWalletWrapper } from "context/Wallet";
import { BigNumber } from "ethers";
import { bigZero, compareAccounts, ether, toBN, toEther } from "utils/ethers";
import { useGetUserTTBalance } from "hooks/useGetUserTTBalance";
import {
  IBetResult,
  initBetResult,
  searchPastBlocks,
  useGameContext,
} from "context/Game/Context";
import Header from "components/Header";
import { displayEllipsedAddress, formatDollarString, networkID } from "utils/helpers";
import { displayItems, startingBlock } from "constants/index";
import Dices from "./Dices";
import Chips from "./Chips";
import History from "./History";

interface IRecord {
  record_id: number;
  player: string;
  bet_price: string;
  bet_dices: number[];
  result_dices: number[];
  result_value: string;
  player_active: boolean;
}

export default function Game() {
  const { account } = useWalletWrapper();
  const ttBalance = useGetUserTTBalance();
  const initBets = [0, 0, 0, 0, 0, 0];
  const [betList, setbetList] = useState(initBets);
  const [activeChip, setactiveChip] = useState(0);
  const [isJSInit, setisJSInit] = useState(true);
  const [betResult, setbetResult] = useState<IBetResult>(initBetResult);
  const sicbo = useSicboContract();
  const [records, setrecords] = useState<IRecord[]>([]);
  const [isOnlyMe, setisOnlyMe] = useState(false);
  const [selfBetResults, setselfBetResults] = useState<IBetResult[]>([]);
  const { allBetResults, getBetResults, minMax, block } = useGameContext();
  const [isOkDisabled, setisOkDisabled] = useState(false);
  const [isInitChip, setisInitChip] = useState(true);
  const [isHistoryShow, setisHistoryShow] = useState(false);

  const clearBets = () => {
    setbetList(initBets);
  };

  const isRollDisabled = !account || activeChip === 0;

  const stopDiceRoll = () => {
    // @ts-ignore
    document
      .querySelector(".game-main img.photo")
      .classList.remove("animation");
  };

  const getSelfBetRestuls = useCallback(async () => {
    if (sicbo) {
      const eventFilter = sicbo.filters.BetResult(account);
      if (block == startingBlock[networkID]) return;
      const betResultEvents = await sicbo.queryFilter(
        eventFilter,
        block - searchPastBlocks
      );
      const res = betResultEvents
        .sort((a, b) => b.blockNumber - a.blockNumber)
        .slice(0, displayItems)
        .map((res) => {
          // @ts-ignore
          const { bets, user, won, rolled } = res.args;
          return { bets, user, won, rolled };
        });
      setselfBetResults(res);
    }
  }, [sicbo, account, block]);

  useEffect(() => {
    // select default chip
    if (isInitChip && ttBalance.gte(ether)) {
      let num = "";
      if (ttBalance.div(ether).gte("10000")) {
        num = "10k";
        setactiveChip(10000);
      } else if (ttBalance.div(ether).gte("5000")) {
        num = "5k";
        setactiveChip(5000);
      } else if (ttBalance.div(ether).gte("2000")) {
        num = "2k";
        setactiveChip(2000);
      } else if (ttBalance.div(ether).gte("1000")) {
        num = "1k";
        setactiveChip(1000);
      } else if (ttBalance.div(ether).gte("100")) {
        num = "100";
        setactiveChip(100);
      } else if (ttBalance.div(ether).gte("10")) {
        num = "10";
        setactiveChip(10);
      } else if (ttBalance.div(ether).gte("5")) {
        num = "5";
        setactiveChip(5);
      } else if (ttBalance.div(ether).gte("1")) {
        num = "1";
        setactiveChip(1);
      }
      setisInitChip(false);
      if (num) {
        const betEl = document.getElementById(`bet-value${num}`);
        betEl!.classList.add("active");
        updateSelectChip(num);
      }
    }
  }, [ttBalance, isInitChip]);

  useEffect(() => {
    getSelfBetRestuls();
  }, [getSelfBetRestuls]);

  useEffect(() => {
    const run = async () => {
      if (isJSInit) {
        init();
      }
      setisJSInit(false);
    };

    run();
  }, [sicbo, isJSInit]);

  useEffect(() => {
    const betResults = isOnlyMe ? selfBetResults : allBetResults;
    if (betResults.length > 0) {
      const records = betResults.map((res: IBetResult, index: number) => ({
        record_id: index,
        player: displayEllipsedAddress(res.user) || "",
        bet_price: formatDollarString(
          toEther(res.bets.reduce((accu, cur) => accu.add(cur), bigZero))
        ),
        bet_dices: res.bets.reduce((accu: number[], cur, index) => {
          if (!cur.isZero()) {
            const diceNumber = index + 1;
            return [...accu, diceNumber];
          }
          return accu;
        }, []),
        result_dices: res.rolled.map((item) => item.toNumber()),
        result_value: "+" + formatDollarString(toEther(res.won)),
        player_active: true,
      }));
      setrecords(records);
    } else {
      setrecords([]);
    }
  }, [allBetResults, isOnlyMe, selfBetResults]);

  const getDiceImg = useCallback((index: number) => {
    switch (index) {
      case 1:
        return Dice1;
      case 2:
        return Dice2;
      case 3:
        return Dice3;
      case 4:
        return Dice4;
      case 5:
        return Dice5;
      case 6:
        return Dice6;
      default:
        return Dice1;
    }
  }, []);

  const renderRecordRows = useCallback(() => {
    return records.map((record: IRecord) => (
      <tr>
        <td>{record.player}</td>
        <td>{record.bet_price}</td>
        <td>
          {record.bet_dices.map((dice: number, index: number) => (
            <img alt="dice" className="history-dice" src={getDiceImg(dice)} />
          ))}
        </td>
        <td>
          {record.result_dices.map((dice: number, index: number) => (
            <img alt="dice" className="history-dice" src={getDiceImg(dice)} />
          ))}
        </td>
        <td>{record.result_value}</td>
      </tr>
    ));
  }, [records, getDiceImg]);

  const watchBetResult = async () => {
    if (sicbo) {
      // filter by user
      sicbo.on(
        "BetResult",
        (
          user: string,
          bets: BigNumber[],
          triple: BigNumber[],
          largeSmall: BigNumber[],
          rolled: BigNumber[],
          won: BigNumber,
          moodWon: BigNumber
        ) => {
          if (compareAccounts(user, account)) {
            setbetResult({ user, bets, rolled, won });
            stopDiceRoll();
            sicbo.removeListener("BetResult", () => {});
          }
        }
      );
    }
  };

  const onRoundFinish = () => {
    setbetList(initBets);
    setbetResult(initBetResult);
    onOKClick();
  };

  const onRoll = async () => {
    setisOkDisabled(true);
    watchBetResult();
    const betSum = betList
      .reduce((accu, cur) => accu.add(cur), bigZero)
      .mul(ether);
    if (betSum.gt(ttBalance)) alert(`Balance not enough!`);
    else if (betSum.gt(minMax[1]))
      alert(`Bet amount has to be smaller than ${toEther(minMax[1])} TT!`);
    else if (betSum.lt(minMax[0]))
      alert(`Bet amount has to be bigger than ${toEther(minMax[0])} TT!`);
    else {
      try {
        renderBetResults();
        const betAmounts = betList.map((bet: number) => toBN(bet).mul(ether));
        const tx = await sicbo!.bet(betAmounts, [0,0,0,0,0,0], [0,0], {
          value: betSum,
          gasLimit: 5000000,
        });
        await tx.wait();
        getBetResults();
        getSelfBetRestuls();
      } catch (error) {
        console.log("error", error);
        // @ts-ignore
        document.querySelector(".rolling-block").classList.remove("active");
      } finally {
        setisOkDisabled(false);
      }
    }
  };

  return (
    <div style={{ position: "relative" }}>
      <div className="content">
        <div className="container game">
          <div className="bet-block">
            <Header />
            <Chips
              clearBets={clearBets}
              isRollDisabled={isRollDisabled}
              onRoll={onRoll}
              setactiveChip={setactiveChip}
            />
            <div className="hr" />
            <Dices
              activeChip={activeChip}
              betList={betList}
              setbetList={setbetList}
              clearBets={clearBets}
              onRoll={onRoll}
              isRollDisabled={isRollDisabled}
              setisHistoryShow={setisHistoryShow}
            />
          </div>
          {isHistoryShow && (
            <History
              setisOnlyMe={setisOnlyMe}
              isOnlyMe={isOnlyMe}
              renderRecordRows={renderRecordRows}
            />
          )}
        </div>
      </div>
      <div className="rolling-block">
        <div className="game-block">
          <div className="game-header">
            <img alt="dice" src={Icon_Result} />
            <span className="result-title">RESULT</span>
            <div className="result-price">+{toEther(betResult.won)}TT</div>
          </div>
          <hr />
          <div className="game-main">
            <img alt="dice" className="photo" src={Photo_Transitions} />
            <div className="dice-result">
              {betResult.user && (
                <main>
                  <div className="dice">
                    {Array.from(new Array(3).keys()).map((key: number) => (
                      <ol
                        key={key}
                        className="die-list even-roll"
                        id="die-1"
                        data-roll={betResult.rolled[key].toNumber()}
                      >
                        <li className="die-item" data-side={1} />
                        <li className="die-item" data-side={2} />
                        <li className="die-item" data-side={3} />
                        <li className="die-item" data-side={4} />
                        <li className="die-item" data-side={5} />
                        <li className="die-item" data-side={6} />
                      </ol>
                    ))}
                  </div>
                  <div className="dice dice-price">
                    {Array.from(new Array(3).keys()).map((key: number) => (
                      <ol key={key} style={{ listStyleType: "none" }}>
                        <li className="price">
                          ${betList[betResult.rolled[key].toNumber() - 1]}
                        </li>
                      </ol>
                    ))}
                  </div>
                </main>
              )}
            </div>
            <div className="btns">
              <button
                className="btn-ok-button"
                onClick={onRoundFinish}
                disabled={isOkDisabled}
              >
                OK
              </button>
              <button
                className="btn-ok-button"
                onClick={onRoll}
                disabled={isOkDisabled}
              >
                Roll Again
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
