import { useMemo } from "react";
import { useWalletWrapper } from "context/Wallet";
import { AbiItem } from "web3-utils";
import { networkID } from "utils/helpers";
import { JsonRpcProvider } from "@ethersproject/providers";
import Sicbo from "constants/abis/Sicbo.json";
import { getProvider, isAddress } from "utils/ethers";
import { Contract, ethers } from "ethers";
import { contractAddresses } from "constants/index";

export function getProviderOrSigner(library: any): any {
  if (library instanceof JsonRpcProvider) return library;
  const provider = getProvider(library);
  const signer = provider.getSigner().connectUnchecked();
  return signer;
}

export function getContract(
  address: string,
  ABI: any,
  library: any
): Contract | null {
  if (!isAddress(address) || address === ethers.constants.AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`);
  }
  return new ethers.Contract(address, ABI, getProviderOrSigner(library));
}

function useContract(address: string, abi: AbiItem) {
  const { ethereum: library } = useWalletWrapper();
  try {
    return useMemo(
      () => getContract(address, abi, library),
      [library, address, abi]
    );
  } catch (error) {
    // console.warn('Failed to get contract', error)
    return null;
  }
}

// export function useERC20Contract(address?: string) {
//   const erc677Abi = erc677 as unknown as AbiItem;
//   return useContract(address || "", erc677Abi);
// }

export function useSicboContract() {
  const abi = Sicbo as unknown as AbiItem;
  const addr = contractAddresses.sicbo[networkID];
  return useContract(addr, abi);
}
