import ConnectButton from "components/Button/ConnectButton";
import { useGetUserTTBalance } from "hooks/useGetUserTTBalance";
import React from "react";
import { toEther } from "utils/ethers";
import { formatDollarString } from "utils/helpers";

export default function Header() {
  const balance = useGetUserTTBalance();
  return (
    <div className="header-block">
      <ConnectButton />
      <div className="pipe">|</div>
      <div>{`Balance: ${formatDollarString(toEther(balance))} TT`}</div>
    </div>
  );
}
