import Navbar from "components/Navbar";
import { GameProvider } from "context/Game";
import { WalletProvider } from "context/Wallet";
import { UseWalletWrapperProvider } from "context/Wallet/Context";
import { HashRouter } from "react-router-dom";
import RenderRoutes from "utils/routes";
import "./App.css";

function App() {
  return (
    <div className="App">
      <UseWalletWrapperProvider>
        <WalletProvider>
          <GameProvider>
            <HashRouter>
              <Navbar />
              <RenderRoutes />
            </HashRouter>
          </GameProvider>
        </WalletProvider>
      </UseWalletWrapperProvider>
    </div>
  );
}

export default App;
