import Web_ol_BargainingChip_Black from "assets/img/Web_ol_BargainingChip_Black.png";
import Web_ol_BargainingChip_Yellow from "assets/img/Web_ol_BargainingChip_Yellow.png";

export default function Chips({
  clearBets,
  isRollDisabled,
  onRoll,
  setactiveChip,
}: {
  setactiveChip: React.Dispatch<React.SetStateAction<number>>;
  clearBets: () => void;
  isRollDisabled: boolean;
  onRoll: () => void;
}) {
  return (
    <div className="content-block">
      <h3>Choose your chips</h3>
      <div className="bet-value">
        <div className="bet-row">
          {/* <div
            className="single-value"
            onClick={() => setactiveChip(1)}
            id="bet-value1"
          >
            <img
              alt="dice"
              className="value-black"
              src={Web_ol_BargainingChip_Black}
            />
            <img
              alt="dice"
              className="value-yello"
              src={Web_ol_BargainingChip_Yellow}
            />
          </div> */}
          <div
            className="single-value"
            onClick={() => setactiveChip(5)}
            id="bet-value5"
          >
            <img
              alt="dice"
              className="value-black"
              src={Web_ol_BargainingChip_Black}
            />
            <img
              alt="dice"
              className="value-yello"
              src={Web_ol_BargainingChip_Yellow}
            />
          </div>
          <div
            className="single-value"
            onClick={() => setactiveChip(10)}
            id="bet-value10"
          >
            <img
              alt="dice"
              className="value-black"
              src={Web_ol_BargainingChip_Black}
            />
            <img
              alt="dice"
              className="value-yello"
              src={Web_ol_BargainingChip_Yellow}
            />
          </div>
          <div
            className="single-value"
            onClick={() => setactiveChip(50)}
            id="bet-value50"
          >
            <img
              alt="dice"
              className="value-black"
              src={Web_ol_BargainingChip_Black}
            />
            <img
              alt="dice"
              className="value-yello"
              src={Web_ol_BargainingChip_Yellow}
            />
          </div>
          <div
            className="single-value last-value"
            onClick={() => setactiveChip(100)}
            id="bet-value100"
          >
            <img
              alt="dice"
              className="value-black"
              src={Web_ol_BargainingChip_Black}
            />
            <img
              alt="dice"
              className="value-yello"
              src={Web_ol_BargainingChip_Yellow}
            />
          </div>
        </div> 
        <div className="bet-row">
          <div
            className="single-value"
            onClick={() => setactiveChip(1000)}
            id="bet-value1k"
          >
            <img
              alt="dice"
              className="value-black"
              src={Web_ol_BargainingChip_Black}
            />
            <img
              alt="dice"
              className="value-yello"
              src={Web_ol_BargainingChip_Yellow}
            />
          </div>
          <div
            className="single-value"
            onClick={() => setactiveChip(2000)}
            id="bet-value2k"
          >
            <img
              alt="dice"
              className="value-black"
              src={Web_ol_BargainingChip_Black}
            />
            <img
              alt="dice"
              className="value-yello"
              src={Web_ol_BargainingChip_Yellow}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
