import { useWalletWrapper } from "context/Wallet";
import { useEffect, useState } from "react";
import {
  displayEllipsedAddress,
  isMobileOrHub,
  networkID,
} from "utils/helpers";

type ConnectStatus = "connect" | "switchNetwork" | "wrongNetwork" | "connected";

export default function ConnectButton() {
  // const [walletButtonText, setwalletButtonText] = useState('Connect')
  const [connectStatus, setconnectStatus] = useState<ConnectStatus>("connect");
  const {
    error: walletError,
    status,
    account,
    connect,
    reset,
    connectByNetwork,
    currentConnectionType,
  } = useWalletWrapper();
  const isSwitchNetworkSupported =
    (!isMobileOrHub || window.ethereum?.isImToken) &&
    currentConnectionType === "injected";
  // || window.ethereum?.isMetaMask
  const network =
    networkID === "18" ? "Thundercore testnet" : "Thundercore mainnet";

  const isSwitchNetwork =
    isSwitchNetworkSupported &&
    walletError &&
    walletError.message.includes("Unsupported chain");

  const getConnectButtonText = () => {
    switch (connectStatus) {
      case "connected":
        // return displayEllipsedAddress(account);
        return "connected";
      case "switchNetwork":
      // return `Switch to ${network}`;
      case "wrongNetwork":
        return "Wrong network, please switch to ThunderCore";
      default:
        return "connect";
    }
  };

  const login = () => {
    connect();
  };

  useEffect(() => {
    if (account) {
      setconnectStatus("connected");
    } else if (
      // wrong network
      walletError &&
      walletError.message.includes("Unsupported chain")
    ) {
      isSwitchNetworkSupported
        ? setconnectStatus("switchNetwork")
        : setconnectStatus("wrongNetwork");
    } else {
      setconnectStatus("connect");
    }
  }, [account, walletError, isSwitchNetworkSupported]);

  return (
    <button
      type="button"
      className="btn"
      style={{ padding: "8px 25px" }}
      onClick={login}
    >
      {getConnectButtonText()}
    </button>
  );
}
