import React from "react";

export default function History({
  setisOnlyMe,
  isOnlyMe,
  renderRecordRows,
}: {
  setisOnlyMe: React.Dispatch<React.SetStateAction<boolean>>;
  isOnlyMe: boolean;
  renderRecordRows: () => JSX.Element[];
}) {
  return (
    <div className="history-block">
      <div className="header-title">
        <div className="title">RECENT HISTORY</div>
        <button
          type="button"
          className="btn"
          style={{ padding: "4px 13px" }}
          onClick={() => setisOnlyMe((prev) => !prev)}
        >
          {isOnlyMe ? "ALL" : "ONLY ME"}
        </button>
      </div>
      <table>
        <thead>
          <tr>
            <th>PLAYER</th>
            {/* width="30%" */}
            <th colSpan={2}>BET</th>
            <th colSpan={2}>RESULT</th>
          </tr>
        </thead>
        <tbody>{renderRecordRows()}</tbody>
      </table>
    </div>
  );
}
