import React from "react";
import Logo from "assets/img/LOGO.png";
import dice2 from "assets/img/dice2.png";
import { Link } from "react-router-dom";
import { resetBet } from "scripts/script";

export default function Navbar() {
  return (
    <div className="navbar">
      <Link to="/">
        <div className="logo">
          <img src={Logo} alt="logo" />
        </div>
      </Link>
      <div className="right-block">
        {/* <img style={{ marginRight: "1rem" }} src={Knight} alt="ameriacn flag" /> */}
        <a href="https://dice2.diceplanet.xyz">
        <img style={{ marginRight: "1rem" }} src={dice2} alt="ameriacn flag" /> 
        </a>
        
        <Link style={{ marginRight: "1rem" }} to="/">
          PLAY
        </Link>
        <Link style={{ marginRight: "1rem" }} onClick={resetBet} to="/home">
          INTRO
        </Link>
        {/* <img src={Flag} alt="ameriacn flag" /> */}
      </div>
    </div>
  );
}
